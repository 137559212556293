var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "meta-data-item" },
    [
      _c(
        "v-icon",
        { staticClass: "meta-data-item-icon", attrs: { small: "" } },
        [_vm._v(_vm._s(_vm.icon))]
      ),
      _vm._v(" " + _vm._s(_vm.value))
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }