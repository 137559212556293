<template>
  <span class="meta-data-item">
    <v-icon small class="meta-data-item-icon">{{ icon }}</v-icon>
    {{ value }}</span
  >
</template>
<script>
export default {
  name: "MetaDataItem",
  props: {
    value: String,
    icon: String,
    iconColor: String,
  },
};
</script>
<style lang="scss" scoped>
.meta-data-item {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  color: $inqliGray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 14.08px;
}
.meta-data-item:hover {
  color: $inqliNavy;
  .meta-data-item-icon {
    color: $inqliNavy;
  }
}
.meta-data-item-icon {
  margin-right: 0;
  font-weight: 600;
  color: $inqliGray;
}
</style>
