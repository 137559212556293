import { render, staticRenderFns } from "./MetaDataItem.vue?vue&type=template&id=7e0c43c4&scoped=true&"
import script from "./MetaDataItem.vue?vue&type=script&lang=js&"
export * from "./MetaDataItem.vue?vue&type=script&lang=js&"
import style0 from "./MetaDataItem.vue?vue&type=style&index=0&id=7e0c43c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e0c43c4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e0c43c4')) {
      api.createRecord('7e0c43c4', component.options)
    } else {
      api.reload('7e0c43c4', component.options)
    }
    module.hot.accept("./MetaDataItem.vue?vue&type=template&id=7e0c43c4&scoped=true&", function () {
      api.rerender('7e0c43c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/components/MetaDataItem.vue"
export default component.exports