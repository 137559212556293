var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    attrs: { "open-delay": "200" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-card",
              {
                staticClass: "question-container",
                class: { "on-hover": hover },
                attrs: { outlined: "", flat: "", elevation: hover ? 5 : 0 },
                on: { click: _vm.openQuestion }
              },
              [
                _c(
                  "div",
                  { staticClass: "question-image-container" },
                  [
                    _c("v-img", {
                      staticClass: "question-image",
                      attrs: {
                        src: _vm.question.image_url,
                        alt: _vm.question.title,
                        "aspect-ratio": _vm.imageRatio
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "question-info" }, [
                  _c("div", { staticClass: "question-metadata-container" }, [
                    _c("span", { staticClass: "question-type-container" }, [
                      _vm._v(_vm._s(_vm.questionType))
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                  ]),
                  _c("div", { staticClass: "question-title" }, [
                    _vm._v(_vm._s(_vm.question.title))
                  ]),
                  _c(
                    "div",
                    { staticClass: "bottom-meta-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _vm.question.open
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    "x-small": "",
                                    color: "inqliGreen",
                                    "text-color": "white"
                                  }
                                },
                                [_vm._v("open")]
                              )
                            : _c(
                                "v-chip",
                                { attrs: { color: "inqliRed", "x-small": "" } },
                                [_vm._v("closed")]
                              )
                        ],
                        1
                      ),
                      _c("meta-data-item", {
                        attrs: { value: _vm.replies, icon: "mdi-reply" }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }