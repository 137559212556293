<template>
  <v-hover v-slot="{ hover }" open-delay="200">
    <v-card
      class="question-container"
      outlined
      flat
      @click="openQuestion"
      :class="{ 'on-hover': hover }"
      :elevation="hover ? 5 : 0"
    >
      <div class="question-image-container">
        <v-img
          :src="question.image_url"
          :alt="question.title"
          :aspect-ratio="imageRatio"
          class="question-image"
        ></v-img>
      </div>
      <div class="question-info">
        <div class="question-metadata-container">
          <span class="question-type-container">{{ questionType }}</span>
          <span>{{ createdAt }}</span>
        </div>
        <div class="question-title">{{ question.title }}</div>
        <div class="bottom-meta-container">
          <div class="mr-3">
            <v-chip
              x-small
              v-if="question.open"
              color="inqliGreen"
              text-color="white"
              >open</v-chip
            >
            <v-chip v-else color="inqliRed" x-small>closed</v-chip>
          </div>
          <meta-data-item :value="replies" icon="mdi-reply" />
        </div>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import moment from "moment";
import MetaDataItem from "../MetaDataItem.vue";

export default {
  components: {
    "meta-data-item": MetaDataItem,
  },
  props: {
    question: {
      type: Object,
    },
    replyCount: {
      type: Number,
    },
  },
  computed: {
    createdAt() {
      return window.innerWidth >= 350
        ? `• ${moment
            .utc(this.question.created_on)
            .local()
            .format("MMM Do YYYY, h A")}`
        : `• ${moment
            .utc(this.question.created_on)
            .local()
            .format("MMM Do YYYY")}`;
    },
    replies() {
      let msg = `${this.replyCount}`;
      msg += this.replyCount <= 1 ? " reply" : " replies";
      return msg;
    },
    questionType() {
      return this.question.type.toLowerCase().replace("iac-", "");
    },
    imageRatio() {
      return this.pageWidth > 768 ? 1.3 : 0.95;
    },
  },
  methods: {
    openQuestion() {
      this.$router.push(`/questions/${this.question.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-container {
  width: 460px;
  display: flex;
  margin: 10px auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  padding-right: 2px;
  background-color: inherit;
  .question-image-container {
    width: 27%;
  }
  .question-image {
    display: block;
    width: 100%;
    border-radius: 16px;
  }
  .question-type-container {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    width: 50%;
    display: inline-block;
    color: $inqliPurple;
  }
  .question-metadata-container {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    width: 100%;
    text-align: left;
    color: $inqliGray;
  }
  .question-info {
    width: 70%;
    margin-left: 10px;
    height: 100%;
  }
  .question-title {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: $inqliNavy;
    height: 50px;
    overflow: hidden;
  }
  .bottom-meta-container {
    display: flex;
  }
}
@media (max-width: $breakpoint-tablet-large) {
  .question-container {
    width: 400px;
  }
}
@media (max-width: $breakpoint-mobile) {
  .question-container {
    .question-type-container {
      width: 40%;
      margin-top: 5px;
    }
    .question-title {
      font-size: 12px;
      height: 40px;
      overflow: hidden;
    }
    .question-image-container {
      width: 30%;
    }
    .question-info {
      width: 75%;
      margin-left: 6px;
    }
  }
}
</style>
